<template>
    <div class="index">
        <div class="index_head">
            <div class="index_top">
                <div class="left">
                    <img src="../../../public/logo.png" alt=""/>
                    <p>隆昂烟草图册查询</p>
                </div>
                <div class="right">
                    <div class="right_button" @click="login" v-if="!userInfo">
                        登录
                    </div>
                    <div class="user_info" v-if="userInfo">
                        <div class="user_head" @click="changeShowUserInfo">
                            <img :src="userInfo.avatar" alt=""/>
                        </div>
                        <div class="user_mes" @click="changeShowUserInfo">
                            <h3>{{ userInfo.realname }}</h3>
                            <p>部门：<span>{{ userInfo.department }}</span></p>
                        </div>
                        <div class="user_info_model" v-if="showUserInfo">
                            <div class="tt">
                                <div class="ll">
                                    <div class="ll_user_head">
                                        <img :src="userInfo.avatar" alt=""/>
                                    </div>
                                    <div class="ll_user_mes">
                                        <h3>{{ userInfo.realname }}</h3>
                                        <p>部门：<span>{{ userInfo.department }}</span></p>
                                    </div>
                                </div>
                                <div class="rr">
                                    <!--                                    <div class="rr_l">-->
                                    <!--                                        <p>3654</p>-->
                                    <!--                                        <span>浏览</span>-->
                                    <!--                                    </div>-->
                                    <!--                                    <div class="rr_c"></div>-->
                                    <div class="rr_l">
                                        <p>{{ collectIds.length }}</p>
                                        <span>收藏</span>
                                    </div>
                                </div>
                            </div>
                            <div class="bb">
                                <div class="bb_it" @click="redirectUserinfo">
                                    <img src="../../../public/icon-set@2x.png" alt=""/>
                                    <p>账号信息</p>
                                </div>
                                <div class="bb_it" @click="usersc">
                                    <img src="../../../public/icon-clected-y@2x.png" alt=""/>
                                    <p>我的收藏</p>
                                </div>
                                <div class="bb_it" @click="logout">
                                    <img src="../../../public/icon-out@2x.png" alt=""/>
                                    <p>退出登录</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="index_search">
                <div class="index_search_tit">
                    烟草图册查阅
                </div>
                <div class="index_search_box">
                    <div class="ll">
                        <input type="text" name="keyword" v-model="keyword" placeholder="请输入关键词"/>
                    </div>
                    <div class="rr" @click="search">
                        <img src="../../../public/icon-search.png" alt="" srcset=""/>
                        <p>查询</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="index_list">
            <div class="box">

                <div class="item" v-for="item in list" @click="previewPdf(item._id)">
                    <div class="top">
                        <div class="left"
                             v-html="item.highlight ? (item.highlight.title ? item.highlight.title[0] : item._source.title) : item._source.title"></div>
                        <div class="right" @click.stop="collect(item._id)" v-if="collectIds.indexOf(item._id) >= 0">
                            <p>取消收藏</p>
                            <img src="../../../public/icon-sc_active.png" alt=""/>
                        </div>
                        <div class="right" @click.stop="collect(item._id)" v-else>
                            <p>收藏</p>
                            <img src="../../../public/icon-sc.png" alt=""/>
                        </div>
                    </div>
                    <div class="tips">
                        <span>{{ categoryList[category.indexOf(item._source.category_id)].name }}</span>
                    </div>
                    <div class="middle">
                        <p v-html="item.highlight ? (item.highlight.desc ? item.highlight.desc[0] : item._source.desc) : item._source.desc"></p>
                    </div>
                    <div class="bottom">
                        <p>查看详情</p>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
export default {
    data() {
        return {
            showUserInfo: false,
            userInfo: {},
            collectIds: [],
            list: [],
            category: [],
            categoryList: [],
            keyword: '',
        }
    },

    mounted() {
        this.getUserInfo();
        this.getCategory();
    },

    methods: {
        /**
         * 个人信息
         */
        getUserInfo() {
            this.$http.get('/user/getUserInfo').then(res => {
                this.userInfo = res.data.data
                this.getCollect();
            });
        },

        getCategory() {
            this.$http.get('/category/all').then(res => {
                var category = [];
                for (var i = 0; i < res.data.data.length; i++) {
                    category.push(res.data.data[i].id);
                }
                this.categoryList = res.data.data;
                this.category = category;

                this.getList();
            });
        },

        /**
         * 收藏
         */
        getCollect() {
            this.$http.get('/collect/all').then(res => {
                var collectIds = [];
                for (var i = 0; i < res.data.data.length; i++) {
                    collectIds.push("" + res.data.data[i].document_id);
                }
                this.collectIds = collectIds;
            });
        },

        /**
         * 列表
         */
        getList() {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.get('/index/getDoc?keyword=' + this.keyword).then(res => {
                loading.close();
                if (res.data.code == 0) {
                    this.list = res.data.data
                } else {
                    this.$message({type: 'error', message: res.data.msg})
                }
            });
        },

        search() {
            if (this.keyword.length > 0) {
                this.getList();
            }
        },

        collect(id) {
            var that = this;
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.post('/collect/operate', {document_id: id}).then(res => {
                loading.close();
                var collectIds = that.collectIds;
                if (res.data.data.is_collect) {
                    collectIds.push("" + id);
                    var newCollectIds = collectIds;
                } else {
                    var newCollectIds = [];
                    for (var i = 0; i < collectIds.length; i++) {
                        if (collectIds[i] != id) {
                            newCollectIds.push("" + collectIds[i]);
                        }
                    }
                }
                this.collectIds = newCollectIds;
            });
        },

        /**
         * 详情
         */
        previewPdf(id){
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.get('/index/preview?id=' + id).then(res => {
                loading.close();
                if (res.data.code == 0) {
                    // var url = res.data.data.url + '?file=' + encodeURIComponent(res.data.data.file) + '&title=' + encodeURIComponent(res.data.data.title);
                    var url = res.data.data.file;
                    if(res.data.data.file_type == 2){
                        url += '?title=' + encodeURIComponent(res.data.data.title);
                    }
                    window.open(url, '_blank');
                } else {
                    this.$message({type: 'error', message: res.data.msg})
                }
            });
        },


        changeShowUserInfo() {
            this.showUserInfo = !this.showUserInfo;
        },

        login() {
            this.$router.push('/login')
        },

        logout() {
            this.$messageBox.confirm('确定要退出吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                localStorage.removeItem('access_token')
                this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
                this.$router.push('/login')
            });
        },

        redirectUserinfo() {
            this.$router.push('/userinfo')
        },

        usersc() {
            this.$router.push('/usersc')
        },
    }
};
</script>

<style lang="less" scoped>

.index_head {
    width: 100%;
    height: 556px;
    background: url(../../../public/index_top_bg.png) no-repeat;
    background-size: 100% 100%;

    .index_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        .left {
            display: flex;
            align-items: center;

            img {
                display: block;
                width: 81px;
                margin-right: 24px;
            }

            p {
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 600;
                font-size: 32px;
                color: #231815;
                line-height: 48px;
            }
        }

        .right {
            display: flex;
            align-items: center;

            .right_button {
                width: 100px;
                height: 44px;
                background: #C8C8C8;
                border-radius: 0px 3px 3px 0px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 18px;
                color: #FFFFFF;
                line-height: 44px;
                text-align: center;
                cursor: pointer;
            }

            .user_info {
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;

                .user_head {
                    img {
                        display: block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                }

                .user_mes {
                    h3 {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 600;
                        font-size: 14px;
                        color: #231815;
                        line-height: 22px;
                    }

                    p {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 12px;
                        color: #8D8D8D;
                        line-height: 18px;
                        display: flex;
                        align-items: center;

                        span {
                            color: #443B39;
                        }
                    }
                }

                .user_info_model {
                    width: 343px;
                    position: absolute;
                    right: 0;
                    top: 50px;
                    z-index: 2;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
                    border-radius: 12px 12px 12px 12px;
                    padding: 20px;

                    .tt {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 20px;

                        .ll {
                            display: flex;
                            align-items: center;

                            .ll_user_head {
                                img {
                                    display: block;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    margin-right: 8px;
                                }
                            }

                            .ll_user_mes {
                                h3 {
                                    font-family: Microsoft YaHei, Microsoft YaHei;
                                    font-weight: 600;
                                    font-size: 14px;
                                    color: #231815;
                                    line-height: 22px;
                                }

                                p {
                                    font-family: Microsoft YaHei, Microsoft YaHei;
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #8D8D8D;
                                    line-height: 18px;
                                    display: flex;
                                    align-items: center;

                                    span {
                                        color: #443B39;
                                    }
                                }
                            }
                        }

                        .rr {
                            display: flex;
                            align-items: center;

                            .rr_l {
                                display: flex;
                                align-items: center;
                                flex-direction: column;

                                p {
                                    font-family: Microsoft YaHei, Microsoft YaHei;
                                    font-weight: 600;
                                    font-size: 16px;
                                    color: #443B39;
                                    line-height: 24px;
                                    text-align: center;
                                }

                                span {
                                    font-family: Microsoft YaHei, Microsoft YaHei;
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #8D8D8D;
                                    line-height: 18px;
                                    text-align: center;
                                }
                            }

                            .rr_c {
                                width: 2px;
                                height: 20px;
                                background: #dcdcdc;
                                margin: 0 10px;
                            }
                        }
                    }

                    .bb {
                        border-top: 1px solid #DCDCDC;
                        padding-top: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .bb_it {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            cursor: pointer;

                            img {
                                display: block;
                                width: 24px;
                                margin-bottom: 8px;
                            }

                            p {
                                font-family: Microsoft YaHei, Microsoft YaHei;
                                font-weight: 400;
                                font-size: 14px;
                                color: #443B39;
                                line-height: 22px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .index_search {
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .index_search_tit {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 700;
            font-size: 64px;
            color: #EB9114;
            line-height: 68px;
        }

        .index_search_box {
            margin-top: 97px;
            width: 977px;
            display: flex;
            align-items: center;
            background: #231815;
            border-radius: 11px 11px 11px 11px;
            padding: 32px 36px;

            .ll {
                flex: 1;
                margin-right: 18px;

                input {
                    display: block;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    background: #FFFFFF;
                    border-radius: 40px;
                    color: #333;
                    padding: 8px 16px;
                    box-sizing: border-box;
                }
            }

            .rr {
                display: flex;
                align-items: center;
                padding: 8px 21px;
                background: #EB9114;
                border-radius: 60px 60px 60px 60px;
                cursor: pointer;

                img {
                    display: block;
                    width: 16px;
                    margin-right: 6px;
                }

                p {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #FFFFFF;
                    line-height: 24px;
                }
            }
        }
    }
}

.index_list {
    .box {
        width: 1500px;
        margin: 45px auto;
        display: flex;
        align-items: center;
        //justify-content: space-around;
        flex-wrap: wrap;

        .item {
            //flex: 0 0 20%;
            margin-right: 20px;
            width: 360px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            padding: 40px 16px 24px 16px;
            box-sizing: border-box;
            margin-bottom: 44px;

            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 12px;

                .left {
                    flex: 1;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow:ellipsis;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 24px;
                    color: #3D3D3D;
                    line-height: 40px;
                    es-span{
                        color: red;
                    }
                }

                .right {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    p {
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #8D8D8D;
                        line-height: 22px;
                        margin-right: 6px;
                    }

                    img {
                        display: block;
                        width: 24px;
                    }
                }
            }

            .tips {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                span {
                    background: rgba(130, 162, 255, 0.12);
                    border-radius: 2px 2px 2px 2px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #527FFF;
                    line-height: 24px;
                    padding: 0 4px;
                    margin-right: 10px;
                }
            }

            .middle {
                margin-bottom: 25px;
                height: 44px;
                p {
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #3D3D3D;
                    line-height: 22px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }

            .bottom {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                p {
                    width: 100px;
                    height: 36px;
                    background: #EB9114;
                    border-radius: 2px 2px 2px 2px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 36px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .item:nth-child(4n) {
            margin-right: 0;
        }
    }
}
</style>
